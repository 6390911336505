const states: string[] = [
  'le mat',
  'le brillant',
  'le rugueux',
  'le lisse',
  'l\'anguleux',
  'le collant',
  'le glissant',
  'le saillant',
  'le cassant',
  'le fin',
  'l\'épais',
  'le dur',
  'le mou',
  'le rond',
  'le court',
  'le long',
  'le pointu',
  'le léger',
  'le dense',
  'l\'aérien',
  'le compact',
  'le filtré',
  'l\'aléatoire',
  'l\'ordonné',
  'le pointillé',
  'le plié',
  'le détaché',
  'le tendu',
  'le raide',
  'le serré',
  'l\'étiré',
  'le filaire',
  'le torsadé',
  'l\'humide',
  'le sec',
  'le chaud',
  'le froid',
  'le minéral',
  'le végétal',
  'le neuf',
  'l\'usé',
  'le salé',
  'le sucré',
  'le piquant',
  'le rapide',
  'le lent',
  'le plat',
  'le profond',
  'l\'immobile',
  'le mouvant',
  'le rampant',
  'le grimpant',
  'le périssable',
  'l\'ouvert',
  'le fermé',
  'le déplié',
  'le centre',
  'le périphérique',
  'l\'arrêt',
  'le prolongé',
  'le tumultueux',
  'l\'artisanal',
  'l\'industriel',
  'la série',
  'l\'éphémère',
  'le sourd',
  'le réverbéré',
  'l\'absorbé',
  'le ressemblant',
  'le contrasté',
  'l\'estompé',
  'le précis',
  'le flou',
  'le clair',
  'l\'opaque',
  'le perlé',
  'le brûlé'
]

function randomTransition () {
  function getArticle (word): string {
    return {
      la: ['De la ', 'à la '],
      l: ['De l\'', 'à l\'']
    }[word.split(/[ ']/)[0]] || ['Du ', 'au ']
  }

  const words: string[] = []
  words[1] = states[Math.floor(Math.random() * states.length)]
  words[0] = getArticle(words[1])[0]
  words[3] = states.filter(w => w !== words[1])[Math.floor(Math.random() * (states.length - 1))]
  words[2] = getArticle(words[3])[1]

  document.getElementById('pre1').textContent = words[0]
  document.getElementById('val1').textContent = words[1].split(/[ ']/)[1]
  document.getElementById('pre2').textContent = words[2]
  document.getElementById('val2').textContent = words[3].split(/[ ']/)[1]

  document.title = words[0] + words[1].split(/[ ']/)[1] + ' ' + words[2] + words[3].split(/[ ']/)[1]
}

document.getElementById('new-transition').addEventListener('click', randomTransition)
randomTransition()
